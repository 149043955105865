<div class="spb-step-mongo">
  <div>
    <div class="airy">
      <spb-calculator></spb-calculator>
    </div>
    <div class="airy">
      <spb-mini-kalp></spb-mini-kalp>
    </div>
    <!-- Existing loans -->
    @if (showExisting) {
      <mat-expansion-panel class="airy">
        <mat-expansion-panel-header>
          <mat-panel-title>Befintliga lån</mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <spb-existing-display></spb-existing-display>
        </ng-template>
      </mat-expansion-panel>
    }
    <!-- Loan proposals -->
    <mat-expansion-panel class="airy" expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>Förslag på lån</mat-panel-title>
        <mat-panel-description>
          Observera att ränteskillnadsersättning kan utgå om bundna lån löses i
          förtid
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div [ngClass]="{'disable-click-events': controlService.locked$ | async}">
        @if (proposals.length > 0) {
          <div>
            <button
              (click)="resetManualProposal()"
              [disabled]="!manualProposal"
              color="primary"
              mat-raised-button
              style="margin-bottom: 5px;">Återställ
            </button>
            <form [formGroup]="form">
              <div formArrayName="loans">
                <table>
                  <caption style="display: none;">Befintliga lån</caption>
                  <tr>
                    <th scope="col">Belopp</th>
                    <th scope="col">Amortering</th>
                    <th scope="col">Ränta</th>
                    <th scope="col">Räntebindning</th>
                    <th scope="col"></th>
                  </tr>
                  @for (proposal of proposals.controls; let i = $index; track proposal.value.id) {
                    <tr>
                      <td>
                        <mat-form-field [formGroupName]="i" class="amount">
                          <input formControlName="amount" matInput
                                 spbFormatNumber>
                          <span matTextSuffix>kr</span>
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field [formGroupName]="i" class="mortgage">
                          <input formControlName="mortgageAmount" matInput
                                 name="mortgageAmount" spbFormatNumber>
                          <span matTextSuffix>kr/månad</span>
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field [formGroupName]="i" class="narrow">
                          <input class="percent"
                                 formControlName="interestPercent" matInput
                                 type="number">
                          <span matTextSuffix>%</span>
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field [formGroupName]="i" class="condition">
                          <mat-select formControlName="terms" ngDefaultControl>
                            @for (term of termsTexts; track term.terms) {
                              <mat-option [value]="term.terms">{{ term.text }}
                              </mat-option>
                            }
                          </mat-select>

                        </mat-form-field>
                      </td>
                      <td>
                        <div class="padding-bottom-small">
                          <button (click)="removeLoan(i)" color="warn"
                                  mat-stroked-button>
                            <span
                              class="material-symbols-outlined">delete</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  }

                </table>
              </div>
            </form>
            @if (showRemaining) {
              <div class="sub-content">
                <div>
                  <p>Sökt belopp: {{ loanAmount | number:'1.0-2':'fr' }} kr</p>
                  <p [ngClass]="{ left: leftToDistribute }">Kvar att
                    fördela: {{ leftToDistribute | number:'1.0-2':'fr' }}</p>
                </div>
                <div>
                  <button (click)="addOtherLoan()" color="accent"
                          mat-stroked-button>Lägg till
                  </button>
                </div>
              </div>
            }
          </div>
        }
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="airy" expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>Motiveringar till portfölj</mat-panel-title>
      </mat-expansion-panel-header>
      <div [ngClass]="{'disable-click-events': controlService.locked$ | async}">
        <!-- We could not lazy load this since the advice risk not showing up in summary
             if that was not so we could have <ng-template matExpansionPanelContent> -->
        <spb-advice></spb-advice>
      </div>
    </mat-expansion-panel>
    <div class="airy">
      <spb-follow-advice (stepValid)="setValid($event)"></spb-follow-advice>
    </div>
    <mat-expansion-panel class="airy">
      <mat-expansion-panel-header>
        <mat-panel-title>Övriga kommentarer</mat-panel-title>
      </mat-expansion-panel-header>
      <p>Kommentarerna skrivs ut på rådgivningsdokumentationen.</p>
      <div [ngClass]="{'disable-click-events': controlService.locked$ | async}">
        <spb-comments></spb-comments>
      </div>
    </mat-expansion-panel>
  </div>
  <div class="spb-step-info">
    @if (scenarioChange) {
      <p>
        Vid scenario omsättning/villkorsändring föreslås samma amortering som
        kunden redan har.
        <span class="spb-mongo-highlight">Amorteringskravsberäkningen</span> kan
        användas för att diskutera lämplig nivå
        med kund.
      </p>
    }
    <p><span class="spb-mongo-highlight>">Kontrollera</span> alltid
      <span
        class="spb-mongo-highlight">amorterings&shy;kravs&shy;beräkningen</span>.
      Detta är en enkel modell som inte kan hantera komplexa scenarios; t.ex när
      kunden har befintliga tilläggslån.
      Använd bankens amorterings&shy;kravs&shy;snurra vid behov.</p>
    @if (!scenarioChange) {
      <p>Amortering som föreslås är enligt huvudregeln. Om
        kund väljer att amortera enligt <span
          class="spb-mongo-highlight">alternativregeln</span> (Tilläggslån) ska
        föreslagen amortering ändras.</p>
    }
    <p><span class="spb-mongo-highlight">Ändra förslag på lån</span> om det
      finns anledning att upplägget bör vara ett
      annat. Detta är speciellt viktigt då kunden har befintliga lån (scenario
      Höj och Omsättning/villkorsändring)
      eftersom systemet inte tar hänsyn till dessa vid framtagande av
      portföljförslag.
    </p>
    <p>Observera att förslag på lån i rådgivningen ska vara bankens förslag och
      inte kundens önskemål.
    </p>
    <p><span class="spb-mongo-highlight">Granska motiveringarna</span> och
      justera vid behov, t.ex. när ändringar har
      gjorts i ursprungligt
      förslag på lån.</p>
    <p>
      <span class="spb-mongo-highlight">Notera kunds valda lånevillkor om</span>
      dessa <span
      class="spb-mongo-highlight">avviker</span> från bankens förslag. Om kund
      väljer att amortera mera än
      amorteringskravet eller vill fördela amorteringen på flera lån anses det
      inte vara ett frånsteg från våra råd.
    </p>
    @if (scenarioChange) {
      <p>
        <span class="spb-mongo-highlight">Villkorsändringsdag</span> för nytt
        lån är preliminär, fastställs på
        utbetalningsdag.</p>
    }
  </div>
</div>
