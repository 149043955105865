<div>
  <div class="middle-holder">
    <div class="middle">
      <spb-logo [onlyLogoLink]="true" [routePath]="[mainPath]"></spb-logo>
      @if (configService.logInState$ | async; as user) {
        <div class="middle">
          <div class="menu">
            @if (user.isValidUser) {
              <div [routerLink]="[mainPath]">
                Rådgivning
                @if (scenario) {
                  - {{ scenario }}
                }
              </div>
              <button [routerLink]="[customerPath]"  (click)="dataService.reset()" color="primary"
                      mat-raised-button>
                Pågående Rådgivningar
              </button>
              <button (click)="reset()" color="primary" mat-raised-button>
                Ny kund
              </button>
              <button (click)="dataService.save().subscribe()"
                      [disabled]="((controlService.locked$ | async) === true) ||
                                 ((controlService.canSave$ | async) !== true)"
                      color="primary" mat-raised-button>
                Spara
              </button>
            }
          </div>
          @if (user.isValidUser && testService.showTestData) {
            <spb-test></spb-test>
          }
          <div class="spb-filler"></div>
          <div>
            <span>{{ user.name }}</span>
          </div>
          <div class="middle-menu">
            <div class="menu"></div>
            <div class="filler"></div>
            <button [matMenuTriggerFor]="menu" mat-button>
              <span class="material-symbols-outlined">menu</span>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="configService.logout()" mat-menu-item>
                Logga ut
              </button>
              <button
                (click)="testService.showTestData = !testService.showTestData"
                mat-menu-item>
                @if (!testService.showTestData) {
                  <span class="material-symbols-outlined">visibility</span>
                } @else {
                  <span class="material-symbols-outlined">visibility_off</span>
                }
                Test
              </button>
            </mat-menu>
          </div>
        </div>
      }
    </div>
  </div>
</div>
