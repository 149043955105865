{
  "name": "br-app",
  "version": "2.4.2-8de50854.0",
  "license": "Apache-2.0",
  "scripts": {
    "install": "cp ./package.json ./src/assets",
    "ng": "ng",
    "start": "cp ./package.json ./src/assets && ng serve --ssl --host localhost.sparbanken.me",
    "build": "./scripts/version.sh && ng build",
    "test": "ng test --watch=false --code-coverage",
    "watch": "ng test",
    "lint": "ng lint",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.4",
    "@angular/common": "^19.1.4",
    "@angular/compiler": "^19.1.4",
    "@angular/core": "^19.1.4",
    "@angular/forms": "^19.1.4",
    "@angular/platform-browser": "^19.1.4",
    "@angular/platform-browser-dynamic": "^19.1.4",
    "@angular/router": "^19.1.4",
    "@swimlane/ngx-charts": "^20.5.0",
    "html2canvas": "^1.4.1",
    "rxjs": "~7.8.1",
    "tslib": "^2.8.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.6",
    "@angular/cdk": "^19.1.3",
    "@angular/cli": "^19.1.6",
    "@angular/compiler-cli": "^19.1.4",
    "@angular/material": "^19.1.3",
    "@angular/material-luxon-adapter": "^19.1.3",
    "@sparbanken-syd/kalpylator": "^1.0.0",
    "@sparbanken-syd/personnummer": "^3.0.0",
    "@sparbanken-syd/sdc-signing": "^1.1.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^19.0.0",
    "@sparbanken-syd/sparbanken-syd-qr": "^19.0.0",
    "@sparbanken-syd/sparbanken-syd-theme": "^19.0.2",
    "@sparbanken-syd/sparbanken-tax": "^1.0.0",
    "@types/d3-scale": "^4.0.9",
    "@types/d3-selection": "^3.0.11",
    "@types/d3-shape": "^3.1.7",
    "@types/jasmine": "~5.1.5",
    "@types/luxon": "^3.4.2",
    "@types/node": "^22",
    "@types/sparbanken-syd-auth-backend": "^2.3.0",
    "@types/sparbanken-syd-datastore": "^0.4.1",
    "@typescript-eslint/eslint-plugin": "^8.23.0",
    "@typescript-eslint/parser": "^8.23.0",
    "angular-eslint": "^19.0.2",
    "eslint": "^9.19.0",
    "jasmine-core": "~5.5.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "luxon": "^3.5.0",
    "postcss": "^8.5.1",
    "postcss-scss": "^4.0.9",
    "stylelint": "^16.14.1",
    "stylelint-config-sass-guidelines": "^12.1.0",
    "stylelint-config-standard-scss": "^14.0.0",
    "stylelint-scss": "^6.11.0",
    "typescript": "~5.7.3",
    "typescript-eslint": "^8.23.0"
  }
}
