import {Injectable} from '@angular/core'
import {
  Applicant,
  Car,
  Child,
  Income,
  Kalpylator,
  LivingExpenseDeduction,
  Parameters
} from '@sparbanken-syd/kalpylator'
import {BehaviorSubject, Observable, of} from 'rxjs'
import {TTypeOfKalp} from './kalp.service'

/**
 * Base monthly expenses for different household types
 */
const ADULT_EXPENSE = 9600
const TWO_ADULTS_EXPENSE = 8500

const RETIRED_EXPENSE = 8200
const TWO_RETIRED_EXPENSE = 7200

/**
 * Household deduction calculations
 * Compares total individual expenses vs shared household expenses
 */
const TWO_EMPLOYED_DEDUCTION = (2 * ADULT_EXPENSE) - (2 * TWO_ADULTS_EXPENSE)
const MIXED_HOUSEHOLD_DEDUCTION = (ADULT_EXPENSE + RETIRED_EXPENSE) - (TWO_ADULTS_EXPENSE + TWO_RETIRED_EXPENSE)
const TWO_RETIRED_DEDUCTION = (2 * RETIRED_EXPENSE) - (2 * TWO_RETIRED_EXPENSE)

const LIVING_EXPENSE_DEDUCTION: LivingExpenseDeduction[] = [
  {class: ['EMPLOYED', 'EMPLOYED'], deduction: TWO_EMPLOYED_DEDUCTION},
  {class: ['EMPLOYED', 'RETIRED'], deduction: MIXED_HOUSEHOLD_DEDUCTION},
  {class: ['RETIRED', 'RETIRED'], deduction: TWO_RETIRED_DEDUCTION}
]

const YEARLY_TAX_VALUE = 9525
const ALMOST_RETIRED_INCOME_FACTOR = 0.7
const CHILDREN_BENEFIT = 1250
const CHILDREN_BENEFIT_EXTRA: [0, number, number, number, number, number, number] = [0, 0, 150, 730, 1740, 2990, 4240]
const LIVING_EXPENSES = [
  {age: 6, expense: 3800, childrenBenefit: CHILDREN_BENEFIT},
  {age: 13, expense: 4300, childrenBenefit: CHILDREN_BENEFIT},
  {age: 15, expense: 5500, childrenBenefit: CHILDREN_BENEFIT},
  {age: 18, expense: 5500, childrenBenefit: 1041},
  {age: 64, expense: ADULT_EXPENSE},
  {age: 67, expense: RETIRED_EXPENSE},
  {age: 200, expense: RETIRED_EXPENSE}
]
const MONTHLY3_TEMPLATE_INTEREST = 6
const LOAN_DEFAULTS = {
  MORTGAGE: {
    interest: MONTHLY3_TEMPLATE_INTEREST / 100,
    mortgagePercent: 0.01
  },
  BLANCO: {
    interest: 0.1,
    mortgagePercent: 0.1
  },
  CREDIT: {
    interest: 0.1,
    mortgagePercent: 0
  },
  BORGEN: {
    interest: 0.1,
    mortgagePercent: 0.1
  }
}
export const PROPERTY_COST_DEFAULTS = {
  VILLA: {
    runCost: 4800,
    yearlyTax: YEARLY_TAX_VALUE
  },
  HYRES: {
    runCost: 500,
    yearlyTax: 0
  },
  BOSTADSRATT: {
    runCost: 900,
    yearlyTax: 0
  },
  FRITIDSHUS: {
    runCost: 2100,
    yearlyTax: YEARLY_TAX_VALUE
  }
}
const CAR_DEFAULTS = {
  LEASE: {
    cost: 4700
  },
  OWN: {
    cost: 2500
  }
}
const defaultParameters: Parameters = {
  defaultTaxTable: 33,
  domain: 'default',
  livingExpenseDeduction: LIVING_EXPENSE_DEDUCTION,
  livingExpenses: LIVING_EXPENSES,
  cityDwellerFactor: 1,
  almostRetiredThreshold: 62,
  almostRetiredLivingExpense: ADULT_EXPENSE,
  almostRetiredIncomeFactor: ALMOST_RETIRED_INCOME_FACTOR,
  childrenBenefit: CHILDREN_BENEFIT,
  childrenBenefitExtra: CHILDREN_BENEFIT_EXTRA,
  loanDefaults: LOAN_DEFAULTS,
  propertyDefaults: PROPERTY_COST_DEFAULTS,
  carDefaults: CAR_DEFAULTS
}

@Injectable({
  providedIn: 'root'
})
export class NGService {

  public kalps$ = new BehaviorSubject<Kalpylator[]>(new Array(3))

  public parameters$ = new BehaviorSubject<Parameters>(defaultParameters)
  /**
   * New subjects for new kalpylator only
   */
  public ngChildren$ = new BehaviorSubject<Child[]>([])
  public ngApplicants$ = new BehaviorSubject<Applicant[]>([])
  public ngCars$ = new BehaviorSubject<Car[]>([])
  public ngIncomes$ = new BehaviorSubject<Income[]>([])

  private positionMap: Record<TTypeOfKalp, number> = {
    existing: 0,
    new: 1,
    new2p: 2
  }

  constructor() {
    this.getParameters()
  }

  /**
   * The order of the Kalps is important, the current
   * way of sorting them is stupid but will do for now
   * @param kalp
   * @param name
   */
  public setKalp(kalp: Kalpylator, name: TTypeOfKalp): void {
    const kalps = this.kalps$.value
    const pos = this.positionMap[name]
    kalps[pos] = kalp
    this.kalps$.next(kalps)
  }

  /**
   * This should eventually get the parameters from a backend
   */
  public getParameters(): Observable<Parameters> {
    return of(defaultParameters)
  }
}
